import $, { inArray } from "jquery";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

$("body").addClass("loading");

const PROJECT_ID = "paik1iqv";
const DATASET = "production";

const PRODUCTS_LIST = [{
        "id": "1",
        "title": "Brest, le port",
        "img": [
            "photo1.jpg", "photo3.jpg"
        ],
        "price": 50,
        "content": "Linogravure de 21x30cm, imprimée sur du papier épais de 250g de très bonne qualité de format 30x42cm.<br/>Imprimées à la main à Brest, série de 50 exemplaires signés et numérotés.<br>Le tirage est unique, avec des différences d’encrage qui surlignent ou atténuent certains détails."
    },
    {
        "id": "2",
        "title": "Campagne",
        "img": [
            "photo6.jpg", "photo7.jpg"
        ],
        "price": 50,
        "content": "Linogravure de 11x16cm imprimée sur du papier 250g de très bonne qualité de format 12x18cm. Imprimées à la main, série de 10 exemplaires signés et numérotés. Chaque tirage est unique puisqu’il va comporter des différences d’encrage."
    },
    {
        "id": "3",
        "title": "Iceberg",
        "img": ["photo5.jpg"],
        "price": 30,
        "content": "Linogravure de 15x21cm imprimée sur du papier japonais 80g de format 24x32cm. Imprimées à la main à Brest, série de … exemplaires signés et numérotés. Chaque tirage est unique puisqu’il va comporter des différences d’encrage."
    },
    {
        "id": "4",
        "title": "???",
        "img": ["photo4.jpg"],
        "price": 150,
        "content": "La lalalalala lalla lala"
    }, {
        "id": "5",
        "title": "Pie Noire",
        "img": ["photo2.jpg"],
        "price": 200,
        "content": " linogravure de 8x11cm, imprimée sur du papier 250g de format 15x21cm. Tirage noir sur papier kraft : série de … exemplaires. Tirage bleu sur papier blanc : série de … exemplaires. Chaque tirage est unique puisqu’il va comporter des différences d’encrage"
    }
];

let items_in_basket = 0;


(function() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    buildItemGrid();

    showSite();
})();


// function loadFakeContent() {
//     $(".grid ul li:first-child").remove();
//     $.each(PRODUCTS_LIST, function () {
//         let html = "";
//         html += '<li class="cell" data-id="' + $(this)[0].id + '">';
//         html += '<div class="thumbnail"><img src="./img/photos/' + $(this)[0].img[0] + '"/>';
//         html += '</div><div class="info">';
//         html += "<h3>" + $(this)[0].title + "</h3>";
//         html += "</div></li>";
//         $(".grid ul").append(html);

//         // MODAL STUFF
//         $(".grid .cell").click(function () {
//             const id = $(this).data("id");
//             openModal(id);
//         });

//         $("#modal-container").click(function () {
//             $(this).addClass("out");
//             $("body").removeClass("modal-active");
//         });

//     });

//     // $("#link_add_to_basket").click(function () {
//     //     const prodId = $("#modal-container").attr("data-productid");
//     //     addToBasket(prodId);
//     // });

// }


function showSite() {
    $("body").removeClass("loading");

    // init
    const insides = document.querySelectorAll("span.text-inside");
    const headers = document.querySelectorAll("h1");

    insides.forEach((inside, index) => {
        inside.style.left = headers[index].offsetLeft + 10 + "px";
        inside.style.top = headers[index].offsetTop + 7 + "px";

        inside_initial_left_offset[index] = inside.offsetLeft;
        inside_initial_top_offset[index] = inside.offsetTop;
    });

    const restraining_ratio = 0.016;

    function moveInside(e) {
        insides.forEach((inside, index) => {
            gsap.to(inside, 0.5, {
                css: {
                    left: inside_initial_left_offset[index] - e.clientX * restraining_ratio,
                    top: inside_initial_top_offset[index] - e.clientY * restraining_ratio
                }
            });
        });
    }

    // let previous_basket_content = localStorage.getItem("basket_content");
    // if (previous_basket_content !== null) {
    //     previous_basket_content = previous_basket_content.split(",");
    //     items_in_basket = previous_basket_content.length;
    //     updateBasketCount();
    //     updateBasket(previous_basket_content);
    // }

    // HEADER STUFF
    function toggleHeaderClass() {
        $("header").toggleClass("active-header");
    }

    function hideBasket() {
        $(".basket-container").removeClass("basket-visible");
    }

    gsap.to("#header", {
        autoAlpha: 1,
        y: "95%",
        scrollTrigger: {
            trigger: ".logo",
            start: "bottom",
            // markers: true,
            scrub: 1,
            onEnter: toggleHeaderClass,
            onEnterBack: toggleHeaderClass
        }
    });
    gsap.to(".basket-container", {
        autoAlpha: 1,
        scrollTrigger: {
            trigger: ".logo",
            start: "bottom",
            // markers: true,
            scrub: 1,
            onEnter: hideBasket,
            onEnterBack: hideBasket
        }
    });

    $("li.header-link:not(.menu-basket)").click(function() {
        gsap.to(window, {
            duration: 2,
            scrollTo: {
                y: ".section-" + $(this).attr("title"),
                offsetY: 50
            }
        });
    });
    $("li.header-link.menu-basket").click(function() {
        $(".basket-container").toggleClass("basket-visible");
    });
    $(".clear-basket button").click(function() {
        clearBasket();
    });

    window.addEventListener("mousemove", moveInside);

    // RESIZE
    $(window).on("resize", function() {});
}

// // BASKET RELATED STUFF
// function addToBasket(id) {
//     const previous_basket_content = localStorage.getItem("basket_content");
//     let basket_content = [id];
//     if (previous_basket_content !== null) {
//         const arr = previous_basket_content.split(",");
//         arr.forEach((item) => {
//             basket_content.push(item);
//         });
//     }
//     localStorage.setItem("basket_content", basket_content);

//     items_in_basket++;
//     updateBasketCount();
//     updateBasket(basket_content);
// }
// function clearBasket() {
//     localStorage.removeItem("basket_content");
//     items_in_basket = 0;
//     updateBasketCount();
//     updateBasket(null);
// }

// function updateBasketCount() {
//     $("#basket-count").html(items_in_basket);
//     if (items_in_basket > 0) {
//         $("#basket-count").css("visibility", "visible");
//     }
// }

// function updateBasket(content) {
//     // $.getJSON("./src/products-list.json", function(data) {
//     //     if (content !== null) {
//     //         let grand_total = 0;
//     //         $(".basket-container .basket-list ul").html("");
//     //         content.forEach((item) => {
//     //             let html =
//     //                 '<li class="basket-item"><strong>' +
//     //                 data[item - 1].title +
//     //                 "</strong><span>" +
//     //                 data[item - 1].price +
//     //                 "€</span></li>";
//     //             $(".basket-container .basket-list ul").append(html);
//     //             grand_total += data[item - 1].price;
//     //         });
//     //         $(".basket-container .basket-total").html(
//     //             "<b>Total : </b>" + grand_total + "€"
//     //         );
//     //     } else {
//     //         $(".basket-container .basket-list ul").html("Votre panier est vide !");
//     //         $(".basket-container .basket-total").html("0€");
//     //     }
//     // }).fail(function() {
//     //     console.log("basket --- error loading json");
//     // });

//     const data = PRODUCTS_LIST;

//     if (content !== null) {
//         let grand_total = 0;
//         $(".basket-container .basket-list ul").html("");
//         content.forEach((id) => {
//             let html = '<li class="basket-item"><strong>' + data[id - 1].title + "</strong><span>" + data[id - 1].price + "€</span></li>";
//             $(".basket-container .basket-list ul").append(html);
//             grand_total += data[id - 1].price;
//         });
//         $(".basket-container .basket-total").html("<b>Total : </b>" + grand_total + "€");
//     } else {
//         $(".basket-container .basket-list ul").html("Votre panier est vide !");
//         $(".basket-container .basket-total").html("0€");
//     }

// }

function buildItemGrid() {
    const QUERY = encodeURIComponent(`
        *[_type == "linogravure"]{
            _id,
            title,
            order,
            price,
            body,
            "imageUrl1": image1.asset->url,
            "imageUrl2": image2.asset->url,
            "imageUrl3": image3.asset->url
          }  | order(order asc)
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        let list = $(".grid ul");

        if (result.length > 0) {
            $(".grid ul li:first-child").remove();

            result.forEach((item) => {

                let listItem = "";
                listItem += '<li class="cell" data-id="' + item._id + '">';
                listItem += '<div class="thumbnail"><img src="' + item.imageUrl1 + '?w=400"/>';
                listItem += '</div><div class="info">';
                listItem += "<h3>" + item.title + "</h3>";
                listItem += "</div></li>";
                list.append(listItem);

            });

            $(".grid .cell").on("click", function() {
                const id = $(this)[0].dataset.id;
                loadItem(id);
            });
            $(".modal-close").on("click", function() {
                $("#modal-container").addClass("out");
                $("body").removeClass("modal-active");
            });
            $(".modal-left").on("click", function() {
                browseItem(-1)
            });
            $(".modal-right").on("click", function() {
                browseItem(1)
            });

        }
    }).catch((err) => console.error(err));

}

async function loadItem(id) {

    const QUERY = encodeURIComponent(`
        *[_type == "linogravure" && _id == "${id}"]{
            _id,
            title,
            order,
            price,
            body,
            "imageUrl1": image1.asset->url,
            "imageUrl2": image2.asset->url,
            "imageUrl3": image3.asset->url
          }
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        if (result.length > 0) {

            openModal(result[0]);

        }
    }).catch((err) => {
        console.error(err)
    });
}

function browseItem(direction) {
    const QUERY = encodeURIComponent(`
        *[_type == "linogravure"]{
            _id,
            title,
            order,
            price,
            body,
            "imageUrl1": image1.asset->url,
            "imageUrl2": image2.asset->url,
            "imageUrl3": image3.asset->url
          }  | order(order asc)
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        if (result.length > 0) {

            const curid = $("#modal-container")[0].dataset.productid;
            const curind = result.findIndex(x => x._id === curid);

            if (result[curind + direction])
                loadItem(result[curind + direction]._id)
            else {
                if (curind === 0)
                    loadItem(result[result.length - 1]._id)
                else
                    loadItem(result[0]._id)
            }

        }
    }).catch((err) => console.error(err));

}


function openModal(data) {
    $(".modal h2").html(data.title);
    $(".modal p#content").html(data.body);
    $(".modal .modal-price").html(data.price + "€");

    let imgs = [data.imageUrl1, data.imageUrl2, data.imageUrl3];
    $(".modal .images").html("");
    imgs.forEach((item) => {
        if (item)
            $(".modal .images").append('<a class href="' + item + '" target="_blank"><img src="' + item + '?w=500"/></a>');
    });
    $(".modal .images").addClass("slider");

    $("#modal-container").attr("data-productid", data._id);
    $("#modal-container").removeAttr("class").addClass("isActive");
    $("body").addClass("modal-active");
}